import React from 'react';
import logo from '../images/logo/WeBananasLogo-horizontal.svg';
import logoVertical from '../images/logo/WeBananasLogo.svg';
import MenuButton from "./MenuButton";

class Header extends React.Component {
    render(){
        return (
            <header className="App-header">
                <MenuButton/>
                <a href={"/"}><img src={logo} className="App-logo" alt="We Bananas Software Inc" /></a>
                <a href={"/"} className={"d-none d-lg-block"}><img src={logoVertical} className="App-logo-vertical" alt="We Bananas Software Inc" /></a>
            </header>
        );
    }
}

export default Header;
