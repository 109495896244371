import React from 'react';
import { InlineIcon } from '@iconify/react';
import facebook from '@iconify/icons-simple-line-icons/social-facebook';
import pinterest from '@iconify/icons-simple-line-icons/social-pinterest';
import instagram from '@iconify/icons-simple-line-icons/social-instagram';


class Footer extends React.Component {
    render(){
        return (
            <footer className="App-footer">
               <div className="social-media-container">
                   <a className="social-media-icon" href="https://www.facebook.com/WeBananasInc/" target="_blank" rel="noopener noreferrer"><InlineIcon icon={facebook} /></a>
                   <a className="social-media-icon" href="https://www.pinterest.ca/webananas/" target="_blank" rel="noopener noreferrer"><InlineIcon icon={pinterest} /></a>
                   <a className="social-media-icon" href="https://www.instagram.com/webananas_inc/" target="_blank" rel="noopener noreferrer"><InlineIcon icon={instagram} /></a>
                   <p className="d-lg-none">&copy;2019 We Bananas Software Inc.</p>
               </div>
            </footer>
        );
    }
}

export default Footer;
