import React from 'react';
import {
    Switch,
    Route, useLocation,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Home from './views/Home';
import About from "./views/About";
import Apps from "./views/Apps";
import Contact from "./views/Contact";
import Error404 from "./views/errors/404";

export default function Routes() {
    let location = useLocation();
    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="transform"
                timeout={1000}
            >
                <Switch location={location}>
                    <Route path="/about" children={<About />} />
                    <Route path="/apps" children={<Apps />} />
                    <Route path="/contact" children={<Contact />} />
                    <Route exact path="/" children={<Home />} />
                    <Route path="*" children={<Error404 />} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
}
