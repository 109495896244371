import React from 'react';
import {Helmet} from "react-helmet";
import { InlineIcon } from "@iconify/react";
import directionIcon from '@iconify/icons-simple-line-icons/direction';
import envelopeIcon from '@iconify/icons-simple-line-icons/envelope';
import callEnd from '@iconify/icons-simple-line-icons/call-end';
import {Link} from "react-router-dom";
import bg from "../images/BananaPhone.jpg";
import HeroImage from "../components/HeroImage";

export default class Contact extends React.Component {
    render(){
        return (
            <div className="ContactPage">
                <Helmet>
                    <title>Contact | We Bananas Software Inc.</title>
                    <meta name="description" content="We Bananas Software Inc." />
                </Helmet>
                <HeroImage
                    image={bg}
                    type={'dark'}
                    size={'mid'}
                    title="Let's Chat."
                    subtitle="Don't be a stranger, just say hello."
                />
                <div className={"row grey-bg"}>
                    <div className={"col-lg-6"}>
                        <iframe title={"We Bananas Software Inc."}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2278.8560846134587!2d-118.79152778343914!3d55.16829398040107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5390914a6ef93c9b%3A0xf63434b8d539768c!2sWe%20Bananas%20Software%20Inc.!5e0!3m2!1sen!2sca!4v1572976979917!5m2!1sen!2sca"
                            width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen=""></iframe>
                    </div>
                    <div className={"col-lg-6"}>
                        <section>
                            <h3 className={"bordered-header"}>Meet <strong>Us</strong></h3>
                            <ul className="icon-list">
                                <li><InlineIcon icon={directionIcon}/> #104 9824 - 97 Ave, Grande Prairie, AB T8V 7K2</li>
                                <li><InlineIcon icon={callEnd}/> 780-833-3694</li>
                                <li><InlineIcon icon={envelopeIcon}/> <a href="mailto:office@webananas.ca">office@webananas.ca</a></li>
                            </ul>
                        </section>
                    </div>
                </div>
                <section>
                    <h3>Work with <strong className={"primary"}>We Bananas</strong></h3>
                    <p>Check out our <Link to='/apps'>apps</Link> page to see the apps and software that we are making. Do you think you’d be a good fit to work with us? Great! Send us your resume and a unique cover letter.</p>
                </section>
            </div>
        );
    }
}
