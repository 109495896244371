import React from 'react';
import { Helmet } from 'react-helmet';
import karen from '../images/karen-willsey.jpeg';
import HeroImage from "../components/HeroImage";
import bg from "../images/office.jpg";
import {Link} from "react-router-dom";
import bananas from '../images/FistBump.jpg';

class About extends React.Component {
    render(){
        return (
            <div>
                <Helmet>
                    <title>About | We Bananas Software Inc.</title>
                    <meta name="description" content="We Bananas Software Inc." />
                </Helmet>
                <HeroImage
                    image={bg}
                    type={'dark'}
                    title="We Are App Makers."
                    subtitle='"Being weird is a side effect of being awesome" - Sue Fitzmaurice'
                    callToAction="Get in Touch"
                    callToActionLink="/contact"
                />
                <section className="content" id={"About"}>
                    <div className="row">
                        <div className="col-lg-3">
                            <img className="rounded-circle aos-init" data-aos={'fade'} src={karen} alt="Karen Willsey" />
                        </div>
                        <div className="col-lg-9">
                            <h2 className="bordered-header">About <strong className="primary">We Bananas</strong></h2>
                            <p>We Bananas Software was founded in April of 2016. Our founder, Karen Willsey made it her mission to use technology to solve problems that she faced in her daily grind.</p>
                            <p>The name We Bananas came as a suggestion from her husband Kevin, who said ‘hey, companies named after fruit tend to do well, why not bananas’. Good call Kevin. The company was named well, because We Bananas Software is a fun place to work.</p>
                            <p>We are quirky people who create apps and technologies that are cool, useful and innovative. Our team is a (mostly) remote workforce. We pivot regularly and work towards making things that are awesome.</p>
                        </div>
                    </div>
                </section>
                <section className="content center animated-bg">
                    <h4 className={'aos-init'} data-aos={'fade-up'} style={{fontWeight:'bold', color:'#FFFFFF'}}>Awesomeness is a fundamental part of our company culture.</h4>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={bananas} alt="WeBananas Software Inc." />
                        </div>
                        <div className="col-lg-6">
                            <h4 className="bordered-header">What does awesome even mean?</h4>
                            <p>Awesome (in We Bananas world) is an idea that’s as cool or interesting. It can be an app idea, or perhaps a better way of achieving efficiency in the codebase. Maybe it’s something fun or random. Then we take that awesomeness, we eyeball it. We think aloud about use cases, and if it’s not awesome we don’t roll with it.  That’s not to say a potentially awesome thing goes away though… someone puts a twist on it, and it suddenly becomes very awesome and then we roll with it.</p>
                            <p>We’re a positive minded company. This isn’t to say that there aren’t tasks and days where we find ourselves having to grind stuff out; to work with us you gotta be able to squash it when you have disagreements, make compromises and find solutions that meet the criteria of a cooperative-game. We’re not perfect, our team members make mistakes all the time, but we view mistakes as the best tools to shape success. Like the saying goes “Life is full of banana skins. You slip, you carry on.”</p>
                            <p>So that's us.</p>
                            <p>Check out our <Link to='/apps'>apps</Link> page to see the apps and software that we are making. Do you think you’d be a good fit to work with us? Great! Send us your resume and a unique cover letter.</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default About;
