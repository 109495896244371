import React from 'react';
import {Helmet} from "react-helmet";
import bg from "../images/Apps.jpg";
import HeroImage from "../components/HeroImage";
import WeStretch from "../images/WeStretch-the-stretching-app.png";
import {InlineIcon} from "@iconify/react";
import facebook from "@iconify/icons-simple-line-icons/social-facebook";
import pinterest from "@iconify/icons-simple-line-icons/social-pinterest";
import instagram from "@iconify/icons-simple-line-icons/social-instagram";
import WeStretchIcon from "../images/westretch-icon.png";

export default class Apps extends React.Component {
    render(){
        return (
            <div>
                <Helmet>
                    <title>Apps | WeBananas Software Inc.</title>
                    <meta name="description" content="WeBananas Software Inc." />
                </Helmet>
                <HeroImage
                    image={bg}
                    type={'dark'}
                    title="Our Apps."
                    subtitle="Our mission is to create easy to use apps, to help you spend more time doing the things you love."
                    callToAction={'Read More'}
                    callToActionLink={'#Apps'}
                />
                <section id={"Apps"}>
                    <div className={"row"}>
                        <div className={"col-lg-4"}>
                            <img src={WeStretch} alt={"WeStretch: The Stretching App"} />
                        </div>
                        <div className={"col-lg-8"}>
                            <h2><img style={{maxWidth:"50px", marginRight:"20px"}} src={WeStretchIcon} alt={"WeStretch: The Stretching App"} /><strong>WeStretch</strong> <br /><small>The Stretching App</small></h2>
                            <p><em>The use of artificial intelligence to generate custom stretching routines unique to you...every time.</em></p>
                            <p>The science shows staying active means staying healthy. Our app, <strong>WeStretch</strong>, will generate a customized stretching routine just for you, every single day. All you need to start using WeStretch is to tell it how much time you have in a day, and what your current level of activity is. Our AI algorithm crunches numbers, and uses the science of kinesiology to generates perfect stretches for your lifestyle.</p>
                            <p>Use WeStretch daily, and over the course of time your mobility, health and stress levels improve. Exercise is optional, movement is essential.</p>
                            <div className={"button-group"}>
                                <a href={"https://westretch.ca"} target={"_blank"} className={"btn btn-westretch"}>Download Now</a>
                                <a className="btn-social-media facebook" href="https://www.facebook.com/WeStretchApp/" target="_blank" rel="noopener noreferrer"><InlineIcon icon={facebook} /></a>
                                <a className="btn-social-media pinterest" href="https://www.pinterest.ca/westretch/" target="_blank" rel="noopener noreferrer"><InlineIcon icon={pinterest} /></a>
                                <a className="btn-social-media instagram" href="https://www.instagram.com/westretch/" target="_blank" rel="noopener noreferrer"><InlineIcon icon={instagram} /></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
