import React from 'react';
import { NavLink} from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import about from '@iconify/icons-mdi/account-badge-outline';
import home from "@iconify/icons-mdi/home";
import apps from '@iconify/icons-mdi/atom';
import contact from '@iconify/icons-mdi/monitor-cellphone';

class MenuButton extends React.Component {

    onClick(){
        // Use this to close the menu after clicked on (mobile only)
        document.getElementById('MenuToggleCheckbox').checked = false;
        // Use this to scroll back to the top of the page
        window.scrollTo(0,0);
    }

    render(){
        return (
            <div id="menuToggle">
                <input type="checkbox" id="MenuToggleCheckbox" />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu">
                    <li><NavLink exact onClick={this.onClick} to="/"><InlineIcon icon={home}/> Home</NavLink></li>
                    <li><NavLink  onClick={this.onClick} to="/about"><InlineIcon icon={about}/> About</NavLink></li>
                    <li><NavLink  onClick={this.onClick} to="/apps"><InlineIcon icon={apps}/> Apps</NavLink></li>
                    <li><NavLink  onClick={this.onClick} to="/contact"><InlineIcon icon={contact}/> Contact</NavLink></li>
                </ul>
            </div>
        );
    }
}

export default MenuButton;
