import React from 'react';
import { Button } from 'reactstrap';

class Error404 extends React.Component {
    render(){
        return (
            <div className="error-404">
                <h1>Sorry.</h1>
                <p>Looks like the page you are looking for is gone, or has been moved.</p>
                <Button href="/" color="ghost-white">Go Back</Button>
            </div>
        );
    }
}

export default Error404;
