import React from "react";
import { BrowserRouter } from "react-router-dom";
import './styles/App.css';
import Routes from './Routes';
import Header from './components/Header';
import Footer from "./components/Footer";
import AOS from 'aos';

export default function App() {

    AOS.init({
        duration:800
    });

    return (
        <BrowserRouter>
            <Header/>
            <main>
                <Routes />
            </main>
            <Footer/>
        </BrowserRouter>
    );
}
