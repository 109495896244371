import React from 'react';
import bg from '../images/HomeDesk2-opt.jpg';
import HeroImage from '../components/HeroImage';
import {Helmet} from "react-helmet";

export default class Home extends React.Component {
    render(){
        return (
            <div className="Home">
                <Helmet>
                    <title>We Bananas Software Inc.</title>
                    <meta name="description" content="We Bananas Software Inc." />
                </Helmet>
                <HeroImage
                    image={bg}
                    type={'dark'}
                    title="We Bananas Software Inc."
                    subtitle="App Development with Tech Appeal."
                    callToAction={'View Our Apps'}
                    callToActionLink={'/apps'}
                />
            </div>
        );
    }
}
