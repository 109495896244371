import React from 'react';
import { InlineIcon } from '@iconify/react';
import arrowDown from '@iconify/icons-simple-line-icons/arrow-down';


class HeroImage extends React.Component {

    callToAction(){
        let icon;
        if(this.props.callToActionLink){
            if(this.props.callToActionLink.includes('#')){
                icon = <InlineIcon icon={arrowDown}/>;
            } else {
                icon = null;
            }
        }
        return this.props.callToAction ? <div className={"aos-init"} data-aos={"fade-up"} data-aos-delay={150} ><a style={{marginTop:'20px'}} href={this.props.callToActionLink} className="btn btn-ghost-white">{this.props.callToAction} {icon}</a></div>  : null;
    }

    classes(){
        return `Hero ${this.props.type} ${this.props.size}`;
    }

    render(){
        return (
            <div className={this.classes()} style={{ backgroundImage:"url(" + this.props.image + ")" }}>
                <div className="Hero-text">
                    <h1 data-aos="fade-up" className={"aos-init"}>{this.props.title}</h1>
                    <p data-aos-delay={100} className="aos-init" data-aos={"fade-up"}>{this.props.subtitle}</p>
                    {this.callToAction()}
                </div>
            </div>
        );
    }
}

export default HeroImage;
